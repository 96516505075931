var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{staticClass:"modal-user create-user",attrs:{"title":"Usuario","maskClosable":false},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.submitAddData.apply(null, arguments)}}},[_c('a-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('a-col',{staticClass:"user-data",attrs:{"span":11}},[_c('div',{staticClass:"ant-form-item-label"},[_c('span',{staticClass:"label-telephone"},[_vm._v("Teléfono")])]),_c('div',{staticClass:"user-phone"},[_vm._v(" "+_vm._s(_vm.userPhone)+" ")])]),_c('a-col',{attrs:{"span":11}},[_c('a-form-item',{staticClass:"user-data",attrs:{"label":"Tipo"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'type_phone',
              { rules: [{ required: true }], initialValue: '0' } ]),expression:"[\n              'type_phone',\n              { rules: [{ required: true }], initialValue: '0' },\n            ]"}],staticStyle:{"width":"100%"}},[_c('a-select-option',{attrs:{"value":"0"}},[_vm._v(" Móvil ")]),_c('a-select-option',{attrs:{"value":"1"}},[_vm._v(" Casa ")])],1)],1)],1)],1),_c('a-row',{staticClass:"user-data",attrs:{"type":"flex","justify":"space-between"}},[_c('a-col',{attrs:{"span":11}},[_c('a-form-item',{attrs:{"label":"Nombre"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'first_name',
              {
                rules: [
                  {
                    required: true,
                    message: 'El campo nombre es requerido.',
                  } ],
                initialValue: _vm.user ? _vm.user.first_name : ''
              } ]),expression:"[\n              'first_name',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'El campo nombre es requerido.',\n                  },\n                ],\n                initialValue: user ? user.first_name : ''\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"span":11}},[_c('a-form-item',{attrs:{"label":"Apellidos"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'last_name',
              {
                rules: [
                  {
                    required: true,
                    message: 'El campo apellido es requerido.',
                  } ],
                initialValue: _vm.user ? _vm.user.last_name : ''
              } ]),expression:"[\n              'last_name',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'El campo apellido es requerido.',\n                  },\n                ],\n                initialValue: user ? user.last_name : ''\n              },\n            ]"}]})],1)],1)],1)],1),_c('template',{slot:"footer"},[_c('a-button',{key:"back",on:{"click":_vm.goBack}},[_vm._v(" Atrás ")]),_c('a-button',{key:"create",attrs:{"type":"primary"},on:{"click":_vm.submitAddData}},[_vm._v(" "+_vm._s(_vm.user ? 'Editar' : 'Crear')+" ")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }