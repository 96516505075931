<template>
  <a-modal
    v-model="visible"
    class="modal-user"
    title="Usuario"
    :maskClosable="false"
  >
    <div class="modal-content">
      <p class="input-message">Buscar con teléfono</p>
      <a-row type="flex">
        <a-col :span="22">
          <MazPhoneNumberInput
            v-model="userPhone"
            show-code-on-list
            default-country-code="MX"
            :translations="translations"
            no-flags
            v-on:keyup.enter="onSearch"
            size="sm"
            no-example
            maxlength="12"
            :preferred-countries="['MX', 'US']"
            @update="updateNumber($event)"
            v-on:keyup.native.enter="onSearch"
          />
        </a-col>
        <a-col :span="2" class="search">
          <div
            :class="
              userPhone !== '' && result.isValid
                ? 'button-search'
                : 'button-search number-not-valid'
            "
            @click="onSearch"
          >
            <a-icon type="search" />
          </div>
        </a-col>
      </a-row>
      <a-row type="flex" v-if="isSavedXpressAddress">
        <a-col :span="24" class="load-saved-xpress-order">
          <a-button class="btn-confirm" @click="loadAddress">
            Cargar usuario en curso
          </a-button>
        </a-col>
      </a-row>
      <a-row type="flex" v-if="isSavedXpressOrder">
        <a-col :span="24" class="load-saved-xpress-order">
          <a-button class="btn-confirm" @click="loadOrder">
            Cargar orden en curso
          </a-button>
        </a-col>
      </a-row>
      <template v-if="userXpress">
        <a-row type="flex">
          <a-col class="user-name" :span="12">
            <a-row type="flex">
              <a-col>
                {{ getName }}. <span>{{ userXpress.id }}</span> 
              </a-col>
              <a-col class="edit-icon">
                <a-icon type="edit" @click="createUser"/>
              </a-col>
            </a-row>
            <div>
              <p class="address-choice-message" v-if="hasAddresses">Elige una dirección</p>
              <p class="address-choice-message no-address" v-else>Sin direcciones</p>
            </div>
          </a-col>
          <a-col class="user-add-address" :span="12">
            <div class="add-address-btn" @click="createAddress(userXpress)">
              Agregar dirección
            </div>
          </a-col>
        </a-row>
        <div
          class="user-address"
          v-for="address in userAddress"
          :key="address.id"
        >
          <div class="address">
            {{ address.address }}
          </div>
          <div class="address-notes">
            {{ address.notes }}
          </div>
          <div class="user-select-address" @click="deleteAddress(address)">
            <a-icon type="delete" />
          </div>
          <div
            class="user-select-address"
            @click="selectAddress(address, userXpress)"
          >
            Utilizar dirección
          </div>
        </div>
      </template>
      <template v-else-if="searchActive">
        <div class="user-not-found">
          <p>No se encontró ningún usuario</p>
          <div class="user-register" @click="createUser">
            Registrar usuario &#8594;
          </div>
        </div>
      </template>
    </div>
    <template slot="footer">
      <router-link to="menu">
        <a-button class="ant-btn">Atrás</a-button>
      </router-link>
    </template>
  </a-modal>
</template>
<script>
import { MazPhoneNumberInput } from "maz-ui";
import "maz-ui/lib/css/index.css";
import errorM from "@/utils/errors.js";

export default {
  name: "User",
  components: {
    MazPhoneNumberInput,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userXpress: null,
      userAddress: null,
      searchActive: false,
      userPhone: "",
      result: Object,
      createVisible: false,
      translations: {
        countrySelectorLabel: "Código",
        phoneNumberLabel: "Número de teléfono",
      },
    };
  },
  computed: {
    getName() {
      return this.userXpress.first_name + " " + this.userXpress.last_name;
    },
    isSavedXpressOrder() {
      return this.$store.getters.isSavedXpressOrder;
    },
    isSavedXpressAddress() {
      return this.$store.getters.isSavedXpressAddress;
    },
    hasAddresses() {
      return this.userAddress && this.userAddress.length > 0;
    },
  },
  methods: {
    updateNumber(event) {
      this.result = event;
      this.userAddress = null;
      this.userXpress = null;
      this.searchActive = false;
    },
    createUser() {
      if (this.userXpress) {
        this.result = { ...this.result, user: this.userXpress };
      }
      
      this.$emit("createUser", this.result);
    },
    loadAddress() {
      this.$emit("loadAddress", this.result);
    },
    loadOrder() {
      const savedOrder = this.$store.getters.getXpressOrder;
      const orderType = savedOrder.orderTypeSelected;
      const fee = savedOrder.totalDelivery;

      this.$router.push({
        name: "XpressMenu",
        params: { orderType, fee },
      });
    },
    async onSearch() {
      if (this.userPhone !== "" && this.result.isValid) {
        const resp = await this.$store.dispatch("get", {
          location: [
            "find-xpress-client-by-phone-number",
            this.$store.getters.establishmentId,
            this.result.nationalNumber,
          ].join("/"),
        });
        if (resp && resp.user_exists) {
          this.userAddress = resp.address;
          this.userXpress = resp.user;
        } else {
          this.userXpress = null;
        }
        this.searchActive = true;
      } else {
        this.userXpress = null;
        this.searchActive = false;
      }
    },
    async selectAddress(address, user) {
      user.address = address;
      this.$store.commit("auth_userXpress", user);

      const resp = await this.$store.dispatch("get", {
        location: [
          "get-delivery-fee",
          this.$store.getters.establishmentId,
          address.id,
        ].join("/"),
      });

      if(resp.fee < 1) {
        this.$notification.error({
          message: "Seleccionar dirección",
          description: errorM.address['INVALID_DISTANCE'],
          duration: 5,
        });
        return;
      }

      this.$emit("selectAddress", resp.fee);
    },
    async deleteAddress(address) {
      const resp = await this.$store.dispatch(
        "delete",
        ["xpress-addresses", address.id].join("/")
      );

      if (resp.result) {
        this.userAddress = this.userAddress.filter((a) => a.id !== address.id);
        this.$notification.success({
          message: "Eliminar dirección",
          description: "Se ha eliminado la dirección",
          duration: 5,
        });
      } else {
        this.$notification.error({
          message: "Eliminar dirección",
          description: "Error al eliminar dirección en uso",
          duration: 5,
        });
      }
    },
    createAddress(user) {
      this.$store.commit("auth_userXpress", user);
      this.$emit("createAddress", user);
    },
  },
};
</script>
